import React, { useEffect } from 'react';
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { PortableText } from '@portabletext/react';
import BlockContent from "../components/block-content";

import * as styles from "../components/pagestyle/about.css";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
    }
    projects: allSanityAbout {
      edges {
        node {
          _rawContact
          _rawCredits
          _rawFrdescription
          _rawUkdescription
          frdescription{
            children{
              text
            }
          }
          ukdescription{
            children{
              text
            }
          }
          credits{
            children{
              text
            }
          }
          contact{
            children{
              text
            }
          }
          Image{
            asset{
              gatsbyImageData
            }
          }
          id
        }
      }
    }
  }
`;

const AboutPage = props => {
  const { data, errors } = props;
  useEffect(() => {
    document.querySelector('body').style.backgroundColor = '#fff';
  }, []);
  //
  useEffect(() => {
    const links = document.querySelectorAll("a");
    links.forEach((link) => {
      if (link.target) {
        return;
      } else if (link.host !== window.location.host) {
        link.target = "_blank";
        link.rel = "noopener";
      } else {
        link.target = "_self";
      }
    });
  }, []);



  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const projectNodes = (data || {}).projects

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const image = projectNodes.edges[0].node.Image
  const imagePlaceholder = getImage(image.asset)

  return (
    <Layout>
      <SEO title={site.title} description={site.description} />
      <Container>
        <div className='about__container'>
          <div className='about__c1'>
            <div className='about__fr'>
              <h3>FR</h3>
              {projectNodes.edges[0].node._rawFrdescription && <BlockContent blocks={projectNodes.edges[0].node._rawFrdescription || []} />}
            </div>
            <div className='about__contact'>
              {projectNodes.edges[0].node._rawContact && <BlockContent blocks={projectNodes.edges[0].node._rawContact || []} />}
            </div>
          </div>

          <div className='about__c2'>
            <div className='about__uk'>
              <h3>UK</h3>
              {projectNodes.edges[0].node._rawUkdescription && <BlockContent blocks={projectNodes.edges[0].node._rawUkdescription || []} />}
            </div>
            <div className='about__credits'>
              {projectNodes.edges[0].node._rawCredits && <BlockContent blocks={projectNodes.edges[0].node._rawCredits || []} />}
            </div>
          </div>


          <div className='about__c3'>
          {imagePlaceholder && (
                    <GatsbyImage
                      image={imagePlaceholder}
                      alt={'Alicia Luxem photo'}
                    />
                  )}
          </div>

        </div>


      </Container>
    </Layout>
  );
};

export default AboutPage;
